<template>
  <footer class="footer w-full bg-black text-white">
    <div class="footer__container mx-auto">
      <div class="footer__title">
        <h3 class="footer__title-text font-semibold">注意事項</h3>
        <div class="flex flex-1 items-center">
          <span class="h-px w-full bg-white"></span>
        </div>
      </div>
      <ul class="footer__contents-list list-outside list-disc">
        <li class="footer__contents-list-item">
          SNS等でのネタバレはご遠慮ください。<br />ただし、謎解きを遊ぶ様子を動画配信するのは問題ありません。<br />*QRコードは株式会社デンソーウェーブの登録商標です。
        </li>
        <li class="footer__contents-list-item">
          推奨環境は下記となります。
          <br />
          windows：chrome（最新版）/edge（最新版）
          <br />
          mac：chrome（最新版）/safari（最新版）
          <br />
          iOS（17.5）：safari（最新版）/chrome（最新版）
          <br />
          Android（13）：chrome（最新版）
        </li>
      </ul>

      <div class="footer__title">
        <h3 class="footer__title-text font-semibold">
          X・Instagramキャンペーンに<br />関する応募規約
        </h3>
        <div class="flex flex-1 items-center">
          <span class="h-px w-full bg-white"></span>
        </div>
      </div>

      <p class="footer__contents-list-item-subtitle">＜ご注意事項＞</p>
      <ul class="footer__contents-list list-outside list-disc">
        <li class="footer__contents-list-item">
          応募の際、ミニオン・ファンクラブ公式
          <br />
          X（@minion_fanclub）、またはミニオン公式
          <br />
          Instagram（@minion_officialjp）をフォローしてください。
          <br />
          フォローを外さないようお願いします。
        </li>
        <li class="footer__contents-list-item">
          応募の際、SNSアカウントは公開アカウントでご参加ください。
          <br />
          非公開設定にされている場合は抽選対象外となります。
        </li>
        <li class="footer__contents-list-item">
          投稿時は「#ミニオン超捜索」と併せて「#PR」も投稿してください。
        </li>
        <li class="footer__contents-list-item">
          「#ミニオン超捜索
          #PR」のハッシュタグが入っていない投稿、ミニオンが写っている画像が付いていない投稿は抽選対象外となります。
        </li>
        <li class="footer__contents-list-item">
          Instagramでの投稿はフィード投稿でご参加ください。ストーリーズでの応募は無効となりますのでご注意ください。
        </li>
        <li class="footer__contents-list-item">
          応募は何度でも可能です。ただし、当選はお1人様1回となります。
        </li>
        <li class="footer__contents-list-item">
          応募は日本国内在住の方に限らせていただきます。
        </li>
        <li class="footer__contents-list-item">
          個人情報が特定されるような投稿にならないようご注意ください。尚、投稿内容に関して何らかの責任問題が発生した場合は、投稿者が一切の責任を負うこととします。
        </li>
        <li class="footer__contents-list-item">
          投稿された作品を使用して、特定の政治、企業、個人、宗教、思想等の宣伝告知、または商業目的での販売行為は禁止となります。
        </li>
        <li class="footer__contents-list-item">
          運営上、投稿内容が適切でないと当社が判断した場合には、削除要請、または不適切な行為としてX社もしくはMeta社に報告させていただく場合がございます。
        </li>
        <li class="footer__contents-list-item">
          第三者への誹謗、中傷を含む投稿や第三者の権利を侵害するような投稿はないようお願いします。第三者と紛争が生じた際は、投稿者自身の責任によって解決していただきます。
        </li>
        <li class="footer__contents-list-item">
          特定の政治・宗教・思想に関する内容等にならないようご注意ください。
        </li>
        <li class="footer__contents-list-item">
          Xは米国およびその他の国におけるX,Inc.の商標または登録商標です。
        </li>
        <li class="footer__contents-list-item">
          本キャンペーンはX,Inc.とは一切関係がありません。
        </li>
        <li class="footer__contents-list-item">
          Xのご利用にあたっては、Xユーザー契約をご確認ください。
        </li>
        <li class="footer__contents-list-item">
          Instagramは米国およびその他の国におけるInstagram,
          LLCの商標または登録商標です。
        </li>
        <li class="footer__contents-list-item">
          本キャンペーンはInstagram,LLCとは一切関係がありません。
        </li>
        <li class="footer__contents-list-item">
          Instagramのご利用にあたっては、Instagram利用規約をご確認ください。
        </li>
        <li class="footer__contents-list-item">
          投稿から一定期間が経過してもサイト上に表示されない可能性がございますが、投稿頂いた時点で抽選の対象となります。
        </li>
      </ul>

      <p class="footer__contents-list-item-subtitle">＜投稿作品の利用＞</p>
      <ul class="footer__contents-list list-outside list-disc">
        <li class="footer__contents-list-item">
          本キャンペーンに投稿された内容は、映画『怪盗グルーのミニオン超変身』プロモーションのために使用する場合がございます。公式サイト、公式X、公式Instagramおよび告知媒体（雑誌・新聞・TV・WEBサイト）、プロモーションイベントに使用される可能性があります。またその際は、内容を編集する場合がございます。使用方法やデザイン、当該目的での使用許諾についても、投稿時点でご承諾いただいたものとみなします。
        </li>
        <li class="footer__contents-list-item">
          投稿の使用の有無など、個別のお問い合わせにはお答えできませんのでご了承ください。
        </li>
        <li class="footer__contents-list-item">
          投稿された方には、報酬等は一切発生いたしません。
        </li>
        <li class="footer__contents-list-item">
          サイトへの投稿の反映には時間が掛かる場合がございます。
        </li>
        <li class="footer__contents-list-item">
          投稿いただいた内容がサイトに反映されない場合もございますので、ご了承ください。また適切でないと判断した場合は、掲載後に予告なくサイトから掲載を取りやめる場合もございますので、ご了承ください。
        </li>
        <li class="footer__contents-list-item">
          Instagramからの投稿は、投稿から一定期間が経過したものはサイト上から削除される場合がございますが、引き続き抽選の対象となります。
        </li>
        <li class="footer__contents-list-item">
          個別の削除要請等には応じられませんので、ご了承ください。
        </li>
      </ul>

      <p class="footer__contents-list-item-subtitle">
        ＜当選者の選定・当選発表＞
      </p>
      <ul class="footer__contents-list list-outside list-disc">
        <li class="footer__contents-list-item">賞品はお選び頂けません。</li>
        <li class="footer__contents-list-item">
          厳正な審査の上、キャンペーン事務局からの連絡をもって当選のご連絡と代えさせていただきます。
        </li>
        <li class="footer__contents-list-item">
          賞品の発送は2024年9～10月頃を予定しております。
        </li>
        <li class="footer__contents-list-item">
          ご応募状況や当選結果のお問い合わせには、お答えいたしかねますのでご了承ください。
        </li>
        <li class="footer__contents-list-item">
          当選権利は当選者本人に限り、他人への譲渡や現金への引き換えは禁止とさせていただきます。
        </li>
        <li class="footer__contents-list-item">
          当選者決定後、キャンペーン事務局から、当選者に当選をお知らせするダイレクトメッセージを送信させていただきます。ダイレクトメッセージを受け取れるように設定してください。
        </li>
        <li class="footer__contents-list-item">
          当選者には、ダイレクトメッセージにて、当選者のお名前、ご住所、お電話番号等をご入力いただく当選者用フォームをご案内いたします。フォーム入力の期限日までにご入力が確認されない場合、当選が無効となります。
        </li>
        <li class="footer__contents-list-item">
          住所・電話番号不明、不通により、当選者と連絡がとれない場合は、当選の権利が無効となります。
        </li>
      </ul>

      <p class="footer__contents-list-item-subtitle">
        ＜個人情報の取扱について＞
      </p>
      <ul class="footer__contents-list list-outside list-disc">
        <li class="footer__contents-list-item">
          当選者の個人情報に関しましては、株式会社ガイエにて管理いたします。
        </li>
        <li class="footer__contents-list-item">
          お客様からいただいたすべての個人情報は、本キャンペーンに関わる目的のみに使用し、他の目的には使用いたしません。
        </li>
        <li class="footer__contents-list-item">
          法令により認められた場合や発送業務等に必要な範囲で委託先に提供する場合を除き、個人情報をお客様の承諾なく第三者に提供いたしません。
        </li>
      </ul>

      <p class="footer__contents-list-item-subtitle">＜免責事項＞</p>
      <ul class="footer__contents-list list-outside list-disc">
        <li class="footer__contents-list-item">
          選考方法、応募受付の確認、当選・落選についてのご質問、お問い合わせは受け付けていません。
        </li>
        <li class="footer__contents-list-item">
          主催者は、理由の如何を問わず（主催者の故意または重過失による場合を除きます）、応募または投稿内容の主催者による利用にあたって参加者に生じた損害について一切責任を負わないものとします。
        </li>
        <li class="footer__contents-list-item">
          X,Inc.およびInstagram,
          LLCが提供するサービスに不具合が発生した場合は責任を負いかねます。あらかじめご了承ください。
        </li>
        <li class="footer__contents-list-item">
          機種・環境によってサイトが動作しない場合がございます。
        </li>
        <li class="footer__contents-list-item">
          本キャンペーン内容、期間、および賞品は、諸般の事情により予告なく中止または変更される場合があります。キャンペーン運営の中断、または中止により生じるいかなる損害についても、当社が責任を負うものではありません。
        </li>
      </ul>

      <p class="footer__contents-list-item-subtitle">
        ＜プライバシーポリシー＞
      </p>
      <p class="footer__policy">
        株式会社ガイエ
        <a href="https://gaie.jp/privacy/" target="_blank"
          >https://gaie.jp/privacy/</a
        >
      </p>

      <div class="footer__title">
        <h3 class="footer__title-text font-semibold">
          TikTokキャンペーンに<br />関する応募規約
        </h3>
        <div class="flex flex-1 items-center">
          <span class="h-px w-full bg-white"></span>
        </div>
      </div>
      <p class="footer__contents-list-item-subtitle">＜ご注意事項＞</p>
      <ul class="footer__contents-list list-outside list-disc">
        <li class="footer__contents-list-item">
          参加いただく際は必ずアカウントの投稿を“公開”にした状態で参加ください。投稿が非公開の場合は参加とみなされませんのでご注意ください。
        </li>
        <li class="footer__contents-list-item">
          アクセス集中や環境などにより、結果が表示されるまでに時間がかかる場合がございますので予めご了承ください。
        </li>
        <li class="footer__contents-list-item">
          本キャンペーンに何度も応募するため、複数のアカウントを作成し参加した場合、これは応募資格無効とさせていただきます。
        </li>
        <li class="footer__contents-list-item">
          投稿後のくじ、くじ引き後の結果が表示されない場合、大変お手数ですがユニバーサル・ピクチャーズ公式TikTokアカウントまで、DMでお問い合わせください。
        </li>
        <li class="footer__contents-list-item">
          くじを引けるのは1回限りとなります。
        </li>
        <li class="footer__contents-list-item">
          13歳未満(12歳以下)の方は保護者の方がご応募ください。
        </li>
        <li class="footer__contents-list-item">
          賞品ご当選の権利はご本人様のものとし、譲渡はできません。
        </li>
        <li class="footer__contents-list-item">
          通信の際の接続トラブルにつきましては、責任を負いかねます。予めご了承ください。
        </li>
        <li class="footer__contents-list-item">
          インターネット通信料・接続料はお客様の負担となります。
        </li>
        <li class="footer__contents-list-item">
          本キャンペーンのご参加は、日本国内にお住まいの方に限らせていただきます。
        </li>
        <li class="footer__contents-list-item">
          個人情報が特定されるような投稿にならないようご注意ください。尚、投稿内容に関して何らかの責任問題が発生した場合は、投稿者が一切の責任を負うこととします。
        </li>
      </ul>

      <p class="footer__contents-list-item-subtitle">＜当選発表について＞</p>
      <ul class="footer__contents-list list-outside list-disc">
        <li class="footer__contents-list-item">
          キャンペーン対象のハッシュタグを付けてTikTokで投稿後、くじを引くと当落の結果が表示されます。
        </li>
        <li class="footer__contents-list-item">
          あたりが出た方は、当選ページに表示される「当選者情報入力フォーム」より賞品の発送に必要な情報をご入力ください。
        </li>
        <li class="footer__contents-list-item">
          当選された方で、入力内容に不備や誤りがあった場合、また2024年8月19日（月）までに発送先情報の入力がない場合等で、賞品をお届けできない場合は、当選を無効にさせていただく場合がございます。
        </li>
        <li class="footer__contents-list-item">
          賞品はお選びいただけません。到着まで楽しみにお待ちください。
        </li>
        <li class="footer__contents-list-item">
          賞品の発送は2024年9～10月頃を予定しております。諸般の事情によりお届けが遅れる場合もございますので、予めご了承ください。
        </li>
        <li class="footer__contents-list-item">
          抽選方法に関するご質問の受付は行っておりません。
        </li>
      </ul>

      <p class="footer__contents-list-item-subtitle">＜個人情報の取り扱い＞</p>
      <ul class="footer__contents-list list-outside list-disc">
        <li class="footer__contents-list-item">
          ご記入いただきましたお客様の個人情報は、東宝東和株式会社より委託を受けた株式会社ガイエが管理させていただきます。本キャンペーンの賞品発送以外の目的では使用いたしませんので、ご安心ください。
        </li>
        <li class="footer__contents-list-item">
          お客様の個人情報を、お客様の同意なしに、業務委託先外の第三者に開示・提示することはありません（法令などにより開示を求められた場合を除く）。
        </li>
        <li class="footer__contents-list-item">
          その他の個人情報の取扱いにつきましては株式会社ガイエの<a
            href="https://gaie.jp/privacy/"
            target="_blank"
            class="underline"
            >プライバシーポリシー</a
          >をご参照ください。
        </li>
      </ul>

      <img
        class="footer__sponsor mx-auto block"
        src="/images/sponsor.webp"
        alt="sponsor"
      />
    </div>
    <small class="footer__copyright block text-center"
      >Despicable Me 4 © Universal City Studios LLC. All Rights
      Reserved.</small
    >
  </footer>
</template>

<style scoped lang="scss">
.footer {
  padding: 70px 0 40px 0; // 固定
}

.footer__container {
  max-width: calcMinSp(734);
  padding: 0 calcMinSp(20);
}

.footer__title {
  margin-bottom: calcMinSp(50);
}

.footer__title-text {
  font-size: calcMinSp(36);
  padding-right: calcMinSp(20);
  margin-bottom: calcMinSp(20);
  line-height: calcMinSp(55.8);

  @include mq() {
    font-size: 21px;
    padding-right: 15px;
    line-height: normal;
  }
}

.footer__contents-list {
  font-size: calcMinSp(21);
  padding-left: 18px; // リストの円形マークの表示位置を修正
  margin-bottom: calcMinSp(30);

  @include mq() {
    font-size: 11px;
    margin-bottom: 40px;
  }
}

.footer__contents-list-item {
  padding-bottom: calcMinSp(10);
}

.footer__contents-list-item-subtitle {
  font-size: calcMinSp(20.9);

  @include mq() {
    font-size: 11px;
  }
}

.footer__policy {
  font-size: calcMinSp(20.9);
  a {
    text-decoration: underline;
  }
  margin-bottom: calcMinSp(70);

  @include mq() {
    font-size: 11px;
    margin-bottom: 40px;
  }
}

.footer__sponsor {
  max-width: calcMinSp(490);
  margin-bottom: calcMinSp(40);

  @include mq() {
    width: 250px;
  }
}

.footer__copyright {
  font-size: calcMinSp(18);
}
</style>
